import * as React from "react"
import ProductPage from "../component/productPage";
import resources from "../resources";

import Layout from "../component/Layout/Layout";
import image11 from '../assets/img/terrazzo1.jpg';
import image12 from '../assets/img/terrazzo2.jpg';

import image2 from '../assets/img/terrazzo3.jpg';

const TerrazzoPage = () => {
    return (
        <Layout>
            <ProductPage
              content={resources.copy.productPage.Terrazzo}
              image11={image11}
              image12={image12}
              image2={image2}
            />
        </Layout>
    )
}
export default TerrazzoPage
