import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ShowCases from '../contentPanel/showCases';
import {pixels as breakpoints} from '../../config/breakpoints';
import {getWidth} from '../../utils/getWidth';

const ProductPageWrapper = styled.div`
`
const Title = styled.div`
  padding: 20px 2em 10px;
  @media (min-width: ${breakpoints.tablet}) {
    padding: 100px 15em 50px;
  }
`

const Heading = styled.div`
/* Terrazzo is next big: */
  font-size: 40px;
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 90px;
    color: #212121;
    letter-spacing: 0;
    line-height: 90px;
    margin-bottom: 70px;
  }
`
const ContentItem = styled.div`
  /* Lorem ipsum dolor si: */
  font-size: 16px;
  color: #212121;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 22px;
  order: ${props => props.order}
`

const Images = styled.div`
  display: flex;
  flex-direction: row;
  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`
const Image = styled.img`
  width: 50%;
  height: 12em;
  @media (min-width: ${breakpoints.tablet}) {
    width: 50%;
    height: 30em;
  }
`
const ImageCover = styled.img`
  position: relative;
  width: 100%;
  margin: 20px 0;
  @media (min-width: ${breakpoints.tablet}) {
    width: ${props => props.width}px;
    height: 35vw;
    text-align: center;
    margin: 25px 0px 50px;
    left: -100px;
  }
`
const Content = styled.div`
  padding: 20px 30px 10px;
  @media (min-width: ${breakpoints.tablet}) {
    padding: 100px 15em 70px;
  }
`

const Subtitle = styled.div`
/* Our vison: */
font-size: 16px;
color: #A7AEC2;
margin-bottom: 28px;
`
const Statement = styled.div`
  /* Lorem ipsum dolor si: */
  font-size: 30px;
  color: #212121;
  letter-spacing: 0;
  line-height: 46px;
  margin-bottom: 31px;
`
const ContentItems = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }
`

const ProductPage = ({content, image11, image12, image2}) => {

  const [width, setWidth] = useState(getWidth())

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  });

  const updateDimensions = () => {
    setWidth(getWidth());
  }

  return (
    <ProductPageWrapper>
      <Title>
        <Heading>{content.title}</Heading>
        <ContentItem>{content.description}</ContentItem>
      </Title>
      <Images>
        <Image src={image11}/>
        <Image src={image12}/>
      </Images>
      <Content>
        <Subtitle>{content.subtitle}
        </Subtitle>
        <Statement>{content.statement}
        </Statement>

        <ContentItems>
          {content.content.map((i, index) => <ContentItem key={`content-${index}`} order={index === 0
            ? index
            : index + 1}>
              {i}
            </ContentItem>
          )}
          <ImageCover src={image2} order={1} width={width - 280}/>
        </ContentItems>
      </Content>
      <ShowCases/>
    </ProductPageWrapper>
  )
}

ProductPage.propTypes = {
  content: PropTypes.object,
}

ProductPage.defaultProps = {
  content: {},
}

export default ProductPage;